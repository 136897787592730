html, body {
  background-color: #080808;
  color: rgba(255, 255, 255, 0.85);
  height: 100%;
  margin: 0;
  font-family: "Source Sans Pro", Arial, sans-serif;
}

button {
  background-color: transparent;
  border: 1px solid #d87a16;
  border-radius: 6px;
  color: #d87a16;
  cursor: pointer;
  padding: 6px 15px;
}

button:hover {
  border: 1px solid #e89a3c;
  color: #e89a3c;
}

button:active {
  border: 1px solid #aa6215;
  color: #aa6215;
}

h1, h2, h3 {
  margin: 0;
}

input {
  background-color: rgba(255, 255, 255, 0.2);
  border: 0;
  color: white;
  padding: 5px 8px;
}

label {
  font-size: 0.8rem;
}

#root {
  display: flex;
  height: 100%;
}
